<template>
  <div class="bill-water-container">
    <!-- 条件查询框 -->
    <div class="filter-panel">
      <CSSelect i-width="36rpx" style="width: 230px; margin-right: 0">
        <el-date-picker
            v-model="conditions.startDate"
            :editable="false"
            format="yyyy-MM-dd HH:mm"
            placeholder="请选择开始时间"
            prefix-icon="el-icon-time"
            style="width: 214px"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </CSSelect>
      <span class="inline-gray"> </span>
      <CSSelect i-width="36rpx" style="width: 230px">
        <el-date-picker
            v-model="conditions.endDate"
            :editable="false"
            format="yyyy-MM-dd HH:mm"
            placeholder="请选择结束时间"
            prefix-icon="el-icon-time"
            style="width: 214px"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </CSSelect>
      <CSSelect>
        <select v-model="conditions.createUserId">
          <option value="">全部创建人</option>
          <option
              v-for="identity in userNameList"
              :key="identity.userId"
              :value="identity.userId"
          >
            {{ identity.userName }}
          </option>
        </select>
      </CSSelect>
      <div
          style="
          vertical-align: middle;
          margin-bottom: 15px;
          display: inline-block;
        "
      >
        <input
            v-model="conditions.collectName"
            class="inputSelect"
            placeholder="搜索公摊汇总名称"
            type="text"
        />
      </div>
      <button class="btn btn-primary btn-sm" type="button" @click="query()">
        查询
      </button>
    </div>


    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template
            v-slot:header
        >
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary sticky-right"
                v-on:click="_openEditRoomModel()"
            >
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              电费公摊汇总收缴
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>创建时间</th>
            <th>公摊汇总名称</th>
            <th>公摊计费起止日期</th>
            <th>公摊汇总详情</th>
            <th>公摊总用电量(度)</th>
            <th>公摊电价（元/度）</th>
            <th>公摊电费总额（元）</th>
            <th>水电公摊总面积（㎡）</th>
            <th>单位面积公摊费（元/㎡）</th>
            <th>账单数</th>
            <th>已缴费账单数</th>
            <th>剩余公摊电费（元）</th>
            <th>变更记录</th>
            <th>创建人</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-for="item in  list">
            <tr :key="item.id">
              <td style="min-width: 125px">{{ item.createTime }}</td>
              <td>{{ item.equallySharedName }}</td>
              <td style="min-width: 164px;">{{ item.collectBillStartDate }}~{{ item.collectBillEndDate }}</td>
              <td><span class="allow-click" @click="viewSummary(item)">查看</span></td>
              <td>{{ item.equallySharedDosage }}</td>
              <td style="min-width: 130px">{{ item.equallySharedPrice }}</td>
              <td style="min-width: 150px">{{ item.equallySharedTotalAmount }}</td>
              <td style="min-width: 150px">{{ item.equallySharedSpace }}</td>
              <td style="min-width: 170px">{{ item.equallySharedPriceAvg }}</td>
              <td><span v-if="item.billSum" class="allow-click" @click="routerBill(item)">{{ item.billSum }}</span><span
                  v-else>0</span></td>
              <td><span v-if="item.alreadyPayCostBill" class="allow-click"
                        @click="routerBillJ(item)">{{ item.alreadyPayCostBill || 0 }}</span><span v-else>0</span>
              </td>
              <td style="min-width: 160px">{{ item.residueEquallySharedAmount || 0 }}</td>
              <td><span class="allow-click" @click="selectOpen(item)">{{ item.isOpen ? '收起' : '查看' }}</span></td>
              <td>{{ item.createUserName }}</td>
              <td>
                <div class="btn-group">
                  <button
                      :class="['btn','btn-primary','dropdown-toggle',{
                                            'disableBtn':item.billState == 1 && item.isSend == false
                                        }]"
                      :disabled="item.billState == 1 && item.isSend == false"
                      aria-expanded="false"
                      aria-haspopup="true"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu">
                    <!--                                        <li v-show="item.billState != 1"-->

                    <!--                                                @click="_openEditRoomModel(item)"-->
                    <!--                                        >-->
                    <!--                                            <a-->
                    <!--                                                    style="width: 100%;"-->
                    <!--                                            >修改</a>-->
                    <!--                                        </li>-->
                    <li v-show="item.billState != 1" @click="deleteBtn(item)">
                      <a
                          style="width: 100%;"
                      >删除</a>
                    </li>
                    <li v-show="item.billState || item.billState == 0">
                      <a
                          style="width: 100%;"
                      >
                        <button class="butonClass" style="background: none;border: none" @click="issueBills(item)">
                          下发账单
                        </button>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr v-if="item.isOpen" :key="item.createTime+item.id">
              <td
                  colspan="15"
                  style="
                                            background: rgb(230, 228, 228);
                                            width: 100%;
                                            overflow-y: auto;
                                        "
              >
                <table style="width: 100%">
                  <thead>
                  <tr>
                    <th>变更时间</th>
                    <th>变更账单号</th>
                    <th>变更原因</th>
                    <th>变更明细</th>
                    <th>变更前剩余公摊电费</th>
                    <th>变更后剩余公摊电费</th>
                    <th>操作人</th>
                  </tr>
                  </thead>
                  <tbody class="course">
                  <tr
                      v-for="items in  item.detailedList" :key="items.id"
                  >
                    <td class="date-td">
                      {{ items.createTime }}
                    </td>
                    <td>
                      {{ items.billId }}
                    </td>
                    <td>
                      {{ reasonChange[items.changCause] }}
                    </td>
                    <td>
                      <span class="allow-click" @click="biewDetailBtn(items,item,item.detailedList)">查看</span>
                    </td>
                    <td>
                      {{ items.changePrevResidueAmount || '-' }}
                    </td>
                    <td>
                      {{ items.changeNextResidueAmount || '-' }}
                    </td>
                    <td>
                      {{ items.changeUserName }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination component="pagination" name="pagination"></Pagination>
      <!--            添加账单-->
      <SummarCollection :isElectric="true" dialogTitle="电费公摊汇总收缴"></SummarCollection>
      <!--            查看汇总详情-->
      <SummaryDetails :isElectric="true"></SummaryDetails>
      <!--            //查看明细-->
      <SummarViewDetails :dialogTitle="dialogTitle" :dialogWidth="dialogWidth" :isElectric="true"></SummarViewDetails>
    </div>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSTable from "@/components/common/CSTable";
import Pagination from "@/components/Pagination.vue";
import SummarCollection from "./SummarCollection";
import SummaryDetails from './SummaryDetails'
import SummarViewDetails from "@/views/WaterElectricityMeter/PoolBill/Summary/SummarViewDetails";
import {
  billLogUrl,
  deleteEquallySharedCollectUrl,
  queryAllCreateUserUrl,
  queryEquallySharedCollectUrl,
  sendBillUrl
} from "@/requestUrl";

export default {
  name: "electricityFeesSummary",
  data() {
    return {
      dialogWidth: '1000px',
      dialogTitle: '',
      userNameList: [],
      reasonChange: ['添加账单', '修改账单', '删除账单'],
      conditions: {
        regionCode: this.$vc.getCurrentRegion().code,
        startDate: "",
        endDate: "",
        createUserId: "",//创建人
        collectName: "",//公摊名称
        type: 2

      },
      invoiceStatus: [],//全部创建人
      filterHeight: 0,
      list: [],
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  components: {
    CSSelect,
    CSTable,
    Pagination,
    SummarCollection,
    SummaryDetails,
    SummarViewDetails
  },
  mounted() {
    this.$vc.on('querySelect', () => {
      this.query();
    })
    this.query();
    this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    window.addEventListener('resize', () => {
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
    })
    this.$vc.on(
        this.$route.path,
        "pagination",
        "page_event",
        (_currentPage) => {
          this.pageParams.pageNo = _currentPage;
          this.query(_currentPage);
        }
    );
  },
  created() {
    window.addEventListener("keydown", this._queryRoomMethodDown);
    this.selectAll();
  },
  beforeDestroy() {
    window.addEventListener("keydown", this._queryRoomMethodDown);
  },
  deactivated() {
    window.addEventListener("keydown", this._queryRoomMethodDown);
  },

  methods: {
    //跳转到账单页面
    routerBill(item) {
      this.$router.push({
        name: 'billManger',
        params: {
          checkedTaBarNumer: 2,
          checkedTaBard: 2,

          collectId: item.id
        }
      })
    },
    routerBillJ(item) {
      this.$router.push({
        name: 'billManger',
        params: {
          checkedTaBarNumer: 2,
          checkedTaBard: 2,
          collectId: item.id,
          invoiceStatus: 1
        }
      })
    },
    //查看变更明细
    biewDetailBtn(items, item, detailedList) {
      // reasonChange:['添加账单','修改账单','删除账单'],
      this.dialogTitle = "";
      this.dialogWidth = '600px';
      switch (items.changCause) {
        case 0: {
          this.dialogTitle = '查看添加账单明细'
          break
        }
        case 1: {
          this.dialogTitle = '查看修改账单明细'
          this.dialogWidth = '1000px';
          break
        }
        case 2: {
          this.dialogTitle = '查看删除账单明细'
          break
        }

      }
      this.$vc.emit('summarViewDetails', {item, items, detailedList});
    },
    //展开列表查询操作记录
    async selectOpen(item) {
      if (!item.isOpen) {
        let res = await this.$fly.get(billLogUrl, {regionCode: this.$vc.getCurrentRegion().code, collectId: item.id});
        console.log('res');
        console.log(res);
        item.detailedList = res.data ? res.data : [];
        console.log(this.list);
      }
      item.isOpen = !item.isOpen;
    },
    //查询所有创建人、
    selectAll() {
      console.log(11111111);
      this.$fly.get(queryAllCreateUserUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        type: 2//1 水 2 电
      }).then(res => {
        console.log(res);
        if (res.code != 0) {
          return
        }
        console.log('全部创建人');
        console.log(res.data);
        this.userNameList = res.data;
      })
    },
    //查看汇总详情
    viewSummary(item) {
      this.$vc.emit("SummaryDetails", item)
    },
    //下发账单
    issueBills(item) {
      this.$CSDialog.confirm({
        title: '提示',
        message: `确认下发账单并通知用户缴费吗？`,
        onConfirm: () => {
          this.$fly.get(sendBillUrl, {id: item.id, regionCode: this.$vc.getCurrentRegion().code}).then(res => {
            if (res.code != 0) {
              return
            }
            this.$vc.toast("下发账单成功");
            this.$CSDialog.instance.closeDialog();
            this.query();
          })
        },
      })
    },
    //删除
    deleteBtn(item) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定要删除吗?`,
        onConfirm: () => {
          this.$fly.delete(deleteEquallySharedCollectUrl, {id: item.id}).then(res => {
            if (res.code != 0) {
              return
            }
            this.$vc.toast("删除成功");
            this.$CSDialog.instance.closeDialog();
            this.query();
          })
        },
      });

    },
    //删除
    _queryRoomMethodDown(e) {
      if (e.keyCode == 13) {
        this.query();
      }
    },
    //添加
    _openEditRoomModel(item) {
      console.log(item);
      this.$vc.emit('addRoomVisibleHy', item)
    },
    //查询
    query(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly.post(queryEquallySharedCollectUrl, {
        ...this.conditions,
        pageNum: pageNo,
        pageSize
      }).then(res => {
        if (res.code != 0) {
          return
        }
        if (pageNo === 1) {
          this.$vc.emit(this.$route.path, "pagination", "init", {
            total: res.data.total,
            currentPage: pageNo,
            pageSize,
          });
        }
        this.selectAll();
        let arrList = res.data.list;
        this.list = arrList.map(item => {
          return {
            ...item,
            isOpen: false
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.disableBtn {
  background: rgba(204, 204, 204, 1);
  color: #FFFFFF;
  border: none;
  cursor: not-allowed;
}

.inputSelect {
  color: #000;
  width: 240px;
  height: 30px;
  padding-left: 10px;
  border-radius: 4px;
  border: 1px solid #979797;
  margin-right: 20px;
}

.butonClass:hover {
  color: #ffffff;
}
</style>
